<template>
	<div>
		<div class="artists_list">
			<div class="item_row head">
				<div class="ava"></div>
				<div class="project">
					<span>Project</span>
					<div class="sort">
						<div class="asc"></div>
						<div class="desc"></div>
					</div>
				</div>
				<div class="owner">
					<span>Owner</span>
					<div class="sort">
						<div class="asc"></div>
						<div class="desc"></div>
					</div>
				</div>
				<div class="label">
					<span>Label</span>
					<div class="sort">
						<div class="asc"></div>
						<div class="desc"></div>
					</div>
				</div>
				<div class="location">
					<span>Location</span>
					<div class="sort">
						<div class="asc"></div>
						<div class="desc"></div>
					</div>
				</div>
				<div class="releases">
					<span>Releases</span>
					<div class="sort">
						<div class="asc"></div>
						<div class="desc"></div>
					</div>
				</div>
				<div class="followers">
					<span>Followers</span>
					<div class="sort">
						<div class="asc"></div>
						<div class="desc"></div>
					</div>
				</div>
				<div class="more_actions"></div>
			</div>

			<template v-if="artistsLoader">
				<v-skeleton-loader
					type="image"
					class="item_track_list"
					v-for="index in 35"
					:key="index"
				></v-skeleton-loader>
			</template>
			<template v-if="!artistsLoader">
				<div class="item_row data" v-for="artist of SEARCH_ARTISTS_RESULT" :key="artist.id" @click="redirectToArtistPage(artist.id, artist.identifier)">
					<div class="left_box">
						<div class="ava img">
            <img v-if="artist.avatar && artist.avatar.thumbnail"
                 :src="`${artist.avatar.thumbnail['50x50']}?cache=${cacheKey}`"
                 v-bind:srcset="`${artist.avatar.thumbnail['50x50']}?cache=${cacheKey} 1x, ${artist.avatar.thumbnail['50x50@2x']}?cache=${cacheKey} 2x`"
                 :alt="artist.name">
            <img v-else :src="artistNoImage" alt="" class="default_artist_ava">
					</div>
					<div class="project">
						<div class="name">
							{{ artist.name }}
						</div>
					</div>
					</div>
					<div class="actions">
                      <v-skeleton-loader v-if="isLoading(artist.id)" max-width="300" height="34" type="button"></v-skeleton-loader>
                      <button type="button" class="follow_btn" v-if="artist.hasFollow === false && !isLoading(artist.id)" @click.stop="follow(artist)">
                        <span class="icon"></span>
                        <p class="text">Follow</p>
                      </button>
                      <button type="button" class="follow_btn following" v-else-if="!isLoading(artist.id)" @click.stop="unfollow(artist)"
                              @touchend="hoverFollowingButton = false"
                              @mouseover="hoverFollowingButton = artist.id"
                              @mouseleave="hoverFollowingButton = false">
                        <span class="icon"></span>
                        <template v-if="hoverFollowingButton === artist.id"><p class="text">Unfollow</p></template>
                        <template v-else><p class="text">Following</p></template>
                      </button>
                    </div>
					<!-- <div class="owner">-</div>
					<div class="label">-</div>
					<div class="location">-</div>
					<div class="releases">-</div>
					<div class="followers">-</div> -->
					<!-- <div class="more_actions">
						<v-menu
							transition="slide-y-transition"
							:close-on-content-click="false"
							:nudge-width="120"
							offset-y
							nudge-left="60"
							nudge-bottom="15"
							origin="top center"
							content-class="menu-pointer charcoal">
							<template v-slot:activator="{ on, attrs }">
								<button class="more_btn" v-ripple v-bind="attrs" v-on="on"></button>
							</template>
							<v-list>
								<v-list-item v-ripple>
								</v-list-item>
							</v-list>
						</v-menu>
					</div> -->
				</div>
			</template>

		</div>

		<pagination v-if="SEARCH_ARTISTS_RESULT.length && SEARCH_COUNT_ARTISTS > 30"
		            :count="SEARCH_COUNT_ARTISTS"
		            :currentPage="SEARCH_ARTISTS_CURRENT_PAGE"
		            :commit="'SET_SEARCH_ARTISTS_CURRENT_PAGE'"
		></pagination>

		<dialogNeedLogin v-if="needLoginDialog" v-model="needLoginDialog"></dialogNeedLogin>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import pagination from "@/components/pagination";

const dialogNeedLogin = () => import('@/components/dialogs/needLogin');

export default {
	name: 'artistsSearchList',
	components: {
		dialogNeedLogin,
		pagination
	},
	data() {
		return {
			apiUrl: process.env.VUE_APP_API_URL,
			artistsLoader: false,
      artistNoImage: require('../../assets/image/svg/square_avatar.svg'),
	  followBtnLoader: [],
      hoverFollowingButton: false,
      cacheKey: new Date().getTime(),
	  needLoginDialog: false,
		}
	},
	watch: {
		SEARCH_ARTISTS_CURRENT_PAGE() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
			this.getArtists();
		},
	},
	computed: {
		...mapGetters([
			'AUTH_DATA',
			'SEARCH_STRING',
			'SEARCH_ARTISTS_RESULT',
			'SEARCH_COUNT_ARTISTS',
			'SEARCH_ARTISTS_CURRENT_PAGE'
		]),
	},
	mounted() {
		this.scrollToTop();
	},
	methods: {
		...mapActions(['SEARCH_ARTISTS']),
		...mapActions(['ARTIST_FOLLOW', 'ARTIST_UNFOLLOW']),
		redirectToArtistPage(artistId, artistName) {
			// const name = artistName.trim().replace(/ /g, '-');
			this.$router.push({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
		},
		getArtists() {
			this.artistsLoader = true;
			this.scrollToTop();

			let artistsParams;
			const name = this.SEARCH_STRING ? {'name': this.SEARCH_STRING} : '';
			const page = this.SEARCH_ARTISTS_CURRENT_PAGE ? {page: this.SEARCH_ARTISTS_CURRENT_PAGE} : null;
			artistsParams = Object.assign(name, page);

			this.SEARCH_ARTISTS(artistsParams)
				.then(() => {
				})
				.catch(err => {
					console.log(`SEARCH_ARTISTS, ${err}`)
				})
				.finally(() => {
					this.artistsLoader = false;
				});
		},
		scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
		follow(artist) {
      if (this.AUTH_DATA.accessToken) {
        this.followBtnLoader.push(artist.id);
        this.ARTIST_FOLLOW(artist.id)
          .then(result => {
            artist.hasFollow = result === 'success'

            if (result !== 'success') {
              this.errorDialog = true;
            }
          })
          .catch(() => {
            this.errorDialog = true;
          })
          .finally(() => {
            const index = this.followBtnLoader.indexOf(artist.id);
            if (index !== -1) {
              this.followBtnLoader.splice(index, 1);
            }
          });
      } else {
		this.needLoginDialog = true;
	  }
    },
	unfollow(artist) {
		if (this.AUTH_DATA.accessToken) {
		this.followBtnLoader.push(artist.id);
		this.ARTIST_UNFOLLOW(artist.id)
			.then(result => {
			artist.hasFollow = !(result === 'success')
			if (result !== 'success') {
				this.errorDialog = false;
			}
			})
			.catch(err => {
			this.errorDialog = true;
			console.error(`artist unfollow, ${err}`)
			})
			.finally(() => {
			const index = this.followBtnLoader.indexOf(artist.id);
			if (index !== -1) {
				this.followBtnLoader.splice(index, 1);
			}
			});
		} else {
		this.needLoginDialog = true;
	  }
	},
	
    isLoading(artistId) {
      return this.followBtnLoader.includes(artistId);
    },

	}
}
</script>

<style scoped>

</style>
