<template>
	<searchLayout>
		<div class="p-search-details">
			<div class="content-page">
				<v-container>
					<div class="block_search_results">

						<!-- Artists -->
						<template v-if="whatContent === 'artists' || SEARCH_DETAILS_CONTENT === 'artists'">
							<div class="head_content">
								<router-link :to="{name: 'search'}"><span class="material-icons">keyboard_arrow_left</span></router-link>
								Artists
							</div>
							<artistsSearchList></artistsSearchList>
						</template>

						<!-- Releases -->
						<template v-if="whatContent === 'albums' || SEARCH_DETAILS_CONTENT === 'albums'">
							<div class="head_content">
								<router-link :to="{name: 'search'}"><span class="material-icons">keyboard_arrow_left</span></router-link>
								Albums
							</div>
							<albumsSearchList></albumsSearchList>
						</template>

						<!-- Tracks -->
						<template v-if="whatContent === 'tracks' || SEARCH_DETAILS_CONTENT === 'tracks'">
							<div class="head_content">
								<router-link :to="{name: 'search'}"><span class="material-icons">keyboard_arrow_left</span></router-link>
								Tracks
							</div>
							<tracksSearchList></tracksSearchList>
						</template>

						<!-- Record Labels -->
						<template v-if="whatContent === 'labels' || SEARCH_DETAILS_CONTENT === 'labels'">
							<div class="head_content">
								<router-link :to="{name: 'search'}"><span class="material-icons">keyboard_arrow_left</span></router-link>
								Labels
							</div>
							<labelsSearchList></labelsSearchList>
						</template>
					</div>
				</v-container>
			</div>

		</div>
	</searchLayout>
</template>

<script>
import {mapGetters} from 'vuex';

import searchLayout from "@/layouts/searchLayout.vue";
// import boxInputSearch from "@/components/search/boxInputSearch";
import artistsSearchList from '@/components/search/artistsSearchList';
import albumsSearchList from '@/components/search/albumsSearchList';
import tracksSearchList from '@/components/search/tracksSearchList';
import labelsSearchList from '@/components/search/labelsSearchList';

export default {
	name: 'searchDetails',
	components: {
		searchLayout,
	//	boxInputSearch,
		artistsSearchList,
		albumsSearchList,
		tracksSearchList,
		labelsSearchList
	},
	props: ['whatContent'],
	data() {
		return {}
	},
	computed: {
		...mapGetters(['SEARCH_DETAILS_CONTENT']),
	},
	mounted() {
		if (this.whatContent) {
			this.$store.commit('UPDATE_SEARCH_DETAILS_CONTENT', this.whatContent);
		}

		if (!this.whatContent && !this.SEARCH_DETAILS_CONTENT) {
			this.$router.push({name: 'search'});
		}
	},
	methods: {
	}
}
</script>
