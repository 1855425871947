<template>
	<div>
		<div class="artists_list">
			<div class="item_row head">
				<div class="ava"></div>
				<div class="project">
					<span>Project</span>
					<div class="sort">
						<div class="asc"></div>
						<div class="desc"></div>
					</div>
				</div>
				<div class="owner">
					<span>Owner</span>
					<div class="sort">
						<div class="asc"></div>
						<div class="desc"></div>
					</div>
				</div>
				<div class="label">
					<span>Label</span>
					<div class="sort">
						<div class="asc"></div>
						<div class="desc"></div>
					</div></div>
				<div class="location">
					<span>Location</span>
					<div class="sort">
						<div class="asc"></div>
						<div class="desc"></div>
					</div>
				</div>
				<div class="releases">
					<span>Releases</span>
					<div class="sort">
						<div class="asc"></div>
						<div class="desc"></div>
					</div>
				</div>
				<div class="followers">
					<span>Followers</span>
					<div class="sort">
						<div class="asc"></div>
						<div class="desc"></div>
					</div>
				</div>
				<div class="more_actions"></div>
			</div>

			<template v-if="labelsLoader">
				<v-skeleton-loader
					type="image"
					class="item_track_list"
					v-for="index in 35"
					:key="index"
				></v-skeleton-loader>
			</template>
			<template v-if="!labelsLoader">
				<div class="item_row data" v-for="label of SEARCH_RECORD_LABELS_RESULT" :key="label.id">
					<div class="left_box">
						<div class="ava">
            <img v-if="label.logo && label.logo.thumbnail"
                 v-bind:srcset="`${label.logo.thumbnail['50x50']}?cache=${cacheKey} 1x, ${label.logo.thumbnail['50x50@2x']}?cache=${cacheKey} 2x`"
                 :src="`${label.logo.thumbnail['50x50']}?cache=${cacheKey}`"
                 :alt="label.title">
            <img v-else :src="labelNoImage" alt="" class="default_artist_ava">
					</div>
					<div class="project">
						<div class="name" @click="redirectToLabelProfile(label.id, label.title, false)">
							{{ label.title }}
						</div>
					</div>
					</div>
					<!-- <div class="owner">
						<div class="name" v-if="label.owner && label.owner.identifier" @click="redirectToUserProfile(label.owner.id, label.owner.identifier)">
							{{label.owner.fullName}}
						</div>
						<template v-else>-</template>
					</div>
					<div class="label">-</div>
					<div class="location">
						<template v-if="label.address">{{label.address}}</template>
						<template v-else>-</template>
					</div>
					<div class="releases">-</div>
					<div class="followers">{{label.followersCount}}</div>
					<div class="more_actions">
						<v-menu
							transition="slide-y-transition"
							:close-on-content-click="false"
							:nudge-width="120"
							offset-y
							nudge-left="60"
							nudge-bottom="15"
							origin="top center"
							content-class="menu-pointer charcoal">
							<template v-slot:activator="{ on, attrs }">
								<button class="more_btn" v-ripple v-bind="attrs" v-on="on"></button>
							</template>
							<v-list>
								<v-list-item v-ripple>
									<template v-if="isAdmin">
										<div @click="redirectToLabelProfile(label.id, label.title, true)">Edit</div>
									</template>
									<template v-else>
										<button type="button" disabled>Edit</button>
									</template>
								</v-list-item>
								<v-list-item v-ripple>
									<template v-if="isAdmin">
										<button type="button" @click="deleteLabelAccount(label)" class="delete">Delete</button>
									</template>
									<template v-else>
										<button type="button" disabled>Delete</button>
									</template>
								</v-list-item>
							</v-list>
						</v-menu>
					</div> -->
				</div>
			</template>
		</div>

		<pagination v-if="SEARCH_RECORD_LABELS_RESULT.length && SEARCH_COUNT_RECORD_LABELS > 30"
		            :count="SEARCH_COUNT_RECORD_LABELS"
		            :currentPage="SEARCH_RECORD_LABELS_CURRENT_PAGE"
		            :commit="'SET_SEARCH_RECORD_LABELS_CURRENT_PAGE'"
		></pagination>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import pagination from "@/components/pagination";

export default {
	name: 'labelsSearchList',
	components: {
		pagination
	},
	data() {
		return {
			apiUrl: process.env.VUE_APP_API_URL,
			labelsLoader: false,
      labelNoImage: require('../../assets/image/svg/square_avatar.svg'),
      cacheKey: new Date().getTime(),
		}
	},
	watch: {
		SEARCH_RECORD_LABELS_CURRENT_PAGE() {
			this.getLabels();
		},
	},
	computed: {
		...mapGetters([
			'isAdmin',
			'SEARCH_STRING',
			'SEARCH_RECORD_LABELS_RESULT',
			'SEARCH_COUNT_RECORD_LABELS',
			'SEARCH_RECORD_LABELS_CURRENT_PAGE'
		]),
	},
	mounted() {
		this.toTop();
	},
	methods: {
		...mapActions(['SEARCH_RECORD_LABELS']),
		redirectToLabelProfile(labelId, labelTitle, edit) {
			// const name = labelTitle.trim().replace(/ /g, '-');
			this.$router.push({name: 'label-profile', params: {id: labelId, name: window.toHumanFormat(labelTitle), edit: edit}});
		},
		redirectToUserProfile(userId, userIdentifier) {
			// const name = userIdentifier.trim().replace(/ /g, '-');
			this.$router.push({name: 'user-profile', params: {id: userId, name: window.toHumanFormat(userIdentifier)}});
		},
		getLabels() {
			this.labelsLoader = true;
			this.toTop();
			let params;
			const name = this.SEARCH_STRING ? {'name': this.SEARCH_STRING} : '';
			const page = this.SEARCH_RECORD_LABELS_CURRENT_PAGE ? {page: this.SEARCH_RECORD_LABELS_CURRENT_PAGE} : null;
			params = Object.assign(name, page);

			this.SEARCH_RECORD_LABELS(params)
				.catch(err => console.log(`SEARCH_ARTISTS, ${err}`))
				.finally(() => this.labelsLoader = false)
		},
		deleteLabelAccount(label) {
			console.log('deleteLabelAccount', label);
		},
		toTop: function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
	}
}
</script>

<style scoped>

</style>
